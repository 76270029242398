import axios from 'axios'

import {
  USER_CONTACT_REQUEST,
  USER_CONTACT_SUCCESS,
  USER_CONTACT_FAIL
} from '../constants/contactConstants.js'

export const sendcontact =
  (cname, cemail, subject, message) => async (dispatch) => {
    try {
      dispatch({
        type: USER_CONTACT_REQUEST
      })

      const config = {
        'Content-Type': 'application/json'
      }

      const { data } = await axios.post(
        '/api/users/contact',
        { cname, cemail, subject, message },
        config
      )

      dispatch({ type: USER_CONTACT_SUCCESS, payload: data })
      // localStorage.setItem('contactInfo', JSON.stringify(data));
    } catch (err) {
      dispatch({
        type: USER_CONTACT_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message
      })
    }
  }
