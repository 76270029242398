export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'
export const USER_LIST_RESET = 'USER_LIST_RESET'

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET'

export const ADMIN_UPDATE_USER_REQUEST = 'ADMIN_UPDATE_USER_REQUEST'
export const ADMIN_UPDATE_USER_SUCCESS = 'ADMIN_UPDATE_USER_SUCCESS'
export const ADMIN_UPDATE_USER_FAIL = 'ADMIN_UPDATE_USER_FAIL'
export const ADMIN_UPDATE_USER_RESET = 'ADMIN_UPDATE_USER_RESET'

export const ADMIN_GET_USER_REQUEST = 'ADMIN_GET_USER_REQUEST'
export const ADMIN_GET_USER_SUCCESS = 'ADMIN_GET_USER_SUCCESS'
export const ADMIN_GET_USER_FAIL = 'ADMIN_GET_USER_FAIL'
export const ADMIN_GET_USER_RESET = 'ADMIN_GET_USER_RESET'

export const ADMIN_DELETE_USER_REQUEST = 'ADMIN_DELETE_USER_REQUEST'
export const ADMIN_DELETE_USER_SUCCESS = 'ADMIN_DELETE_USER_SUCCESS'
export const ADMIN_DELETE_USER_FAIL = 'ADMIN_DELETE_USER_FAIL'
export const ADMIN_DELETE_USER_RESET = 'ADMIN_DELETE_USER_RESET'
