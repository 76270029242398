import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { getUserDetails, updateUser } from '../actions/adminActions'
import { ADMIN_UPDATE_USER_RESET } from '../constants/adminConstants'

const UserEditScreen = () => {
  const params = useParams()
  const userId = params.id

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isAdmin, setIsAdmin] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [message, setMessage] = useState(null)

  const [masterload, setMasterLoad] = useState(true)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const adminGetUser = useSelector((state) => state.adminGetUser)
  const { user, loading, error } = adminGetUser

  const adminUpdateUser = useSelector((state) => state.adminUpdateUser)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate
  } = adminUpdateUser

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: ADMIN_UPDATE_USER_RESET })
      navigate('/userlist')
    } else {
      if (!user || successUpdate) {
        dispatch(getUserDetails(userId))
      } else if (user && masterload) {
        dispatch(getUserDetails(userId))
        setMasterLoad(false)
      } else {
        setName(user.name)
        setEmail(user.email)
        setIsAdmin(user.isAdmin)
        setIsDisabled(user.isDisabled)
      }
    }
  }, [dispatch, navigate, userId, user, masterload, successUpdate])

  // Update submit handler
  const submitHandler = (e) => {
    e.preventDefault()
    if (password !== confirmPassword) {
      setMessage('Passwords do not match')
    } else {
      dispatch(
        updateUser({
          _id: userId,
          name,
          email,
          password,
          isAdmin,
          isDisabled
        })
      )
    }
  }

  return (
    <>
      <Link to='/userlist' className='btn btn-primary my-2'>
        Go Back
      </Link>
      <FormContainer>
        <h1>Edit User</h1>
        {message && <Message variant='danger'>{message}</Message>}
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group className='mb-2' controlId='name'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type='name'
                placeholder='Enter name'
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group className='mb-2' controlId='email'>
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type='email'
                placeholder='Enter email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group className='mb-2' controlId='password'>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='Enter password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>

            <Form.Group className='mb-2' controlId='confirmPassword'>
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='Confirm password'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Form.Group>

            <Form.Group className='mb-2' controlId='isadmin'>
              <Form.Check
                type='checkbox'
                label='Is Admin'
                checked={isAdmin}
                onChange={(e) => setIsAdmin(e.target.checked)}
              ></Form.Check>{' '}
            </Form.Group>

            <Form.Group className='mb-2' controlId='isdisabled'>
              <Form.Check
                type='checkbox'
                label='Is Disabled'
                checked={isDisabled}
                onChange={(e) => setIsDisabled(e.target.checked)}
              ></Form.Check>
            </Form.Group>
            <Button type='submit' variant='primary'>
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  )
}

export default UserEditScreen
