/**
 * @module Header
 * @description Represents the header component of the application.
 * @returns {JSX.Element} The header component.
 */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Navbar, Nav, NavDropdown, NavLink } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useNavigate } from 'react-router-dom'
import { Routes, Route } from 'react-router-dom'
import { logout } from '../actions/userActions.js'
import SearchBox from '../components/SearchBox.js'

const Header = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // Get userLogin state
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  // Logout handler
  const logoutHandler = () => {
    const id = userInfo._id
    dispatch(logout(id))
    navigate('/login')
  }

  return (
    <header>
      <Navbar bg='dark' variant='dark' expand='lg' collapseOnSelect>
        <Container>
          {!userInfo ? (
            <NavLink href='/'>
              <Navbar.Brand>
                <i className='fas fa-home' /> Geranium Receipt
              </Navbar.Brand>
            </NavLink>
          ) : (
            <Navbar.Brand>
              <i className='fas fa-home' /> Geranium Receipt
            </Navbar.Brand>
          )}

          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            {userInfo && (
              <Routes>
                <Route
                  path='/*'
                  id='basic-navbar-nav'
                  element={<SearchBox />}
                />
              </Routes>
            )}

            <Nav className='ml-auto'>
              {!userInfo && (
                <NavDropdown title='Menu' id='menu'>
                  <LinkContainer to='/login'>
                    <NavDropdown.Item>Login</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/register'>
                    <NavDropdown.Item>Register</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/contact'>
                    <NavDropdown.Item>Contact Us</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/about'>
                    <NavDropdown.Item>About Us</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              )}

              {userInfo && (
                <LinkContainer to='/receipts'>
                  <Navbar.Brand className='small'>Receipts</Navbar.Brand>
                </LinkContainer>
              )}

              {userInfo && !userInfo.isAdmin && (
                <NavDropdown title={userInfo.name} id='username'>
                  <LinkContainer to='/dashboard'>
                    <NavDropdown.Item>Dashboard</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/profile'>
                    <NavDropdown.Item>Profile</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/contact'>
                    <NavDropdown.Item>Contact Us</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Item onClick={logoutHandler}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              )}
              {userInfo && userInfo.isAdmin && (
                <NavDropdown title={userInfo.name} id='username'>
                  <LinkContainer to='/userlist'>
                    <NavDropdown.Item>Users</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/dashboard'>
                    <NavDropdown.Item>Dashboard</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/profile'>
                    <NavDropdown.Item>Profile</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/contact'>
                    <NavDropdown.Item>Contact Us</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Item onClick={logoutHandler}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}

export default Header
