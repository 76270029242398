import React, { useState, useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Form, Button, Container, Row, Col, Card, Alert } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import Message from "../components/Message"
import Loader from "../components/Loader"
import { receiptUpdate, getReceipt } from "../actions/receiptActions"
import { RECEIPT_DETAIL_RESET } from "../constants/receiptConstants"
import moment from "moment"

const ReceiptUpdateScreen = () => {
  const { id } = useParams()

  const [rcategory, setRcategory] = useState("")
  const [rmeta, setRmeta] = useState("")
  const [rcptext, setRcptext] = useState("")

  const [alert, setAlert] = useState({
    show: false,
    message: "",
    variant: "success"
  })

  const showAlert = (message, variant = "success", seconds = 1500) => {
    setAlert({
      show: true,
      message,
      variant
    })

    setTimeout(() => {
      setAlert({
        show: false,
        message: "",
        variant: "success"
      })
    }, seconds)
  }

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const detailReceipt = useSelector((state) => state.detailReceipt)
  const { loading, error, receipt } = detailReceipt

  const updateReceipt = useSelector((state) => state.updateReceipt)
  const { success } = updateReceipt

  useEffect(() => {
    if (!userInfo) {
      navigate("/login")
    } else {
      if ((!receipt || receipt._id !== id) && (success || !loading)) {
        dispatch({ type: RECEIPT_DETAIL_RESET })
        dispatch(getReceipt(id))
      } else {
        setRcategory(receipt.rcategory)
        setRmeta(receipt.rmeta)
        setRcptext(receipt.rcptext)
      }
    }
  }, [receipt, dispatch, id, navigate, success, loading, userInfo])

  // Submit handler
  const submitHandler = (e) => {
    e.preventDefault()
    window.confirm("Are you sure?")
    dispatch(receiptUpdate({ _id: id, rcategory, rmeta, rcptext }))
    showAlert("Receipt Updated", "success")
    dispatch(getReceipt(id))
    navigate(`/receipts/edit/${id}`)
  }

  // Update receipt screen
  return (
    <Container>
      {alert.show && <Alert variant={alert.variant}>{alert.message}</Alert>}
      {loading && <Loader />}
      {error && <Message variant='danger'>{error}</Message>}

      <Row className='my-2'>
        <Col>
          <Link to='/receipts' className='btn btn-primary'>
            Go Back
          </Link>
        </Col>
      </Row>

      <h4>
        Edit Receipt{" "}
        {moment(receipt.createdAt).format("ddd DD MMM YYYY h:mm A")}
      </h4>

      <Card className='mt-2 mb-2'>
        <Card.Body>
          <Form onSubmit={submitHandler}>
            <Row className='my-2'>
              <Col>
                <img
                  src={`/static/${receipt.qfname}`}
                  alt='placeholder'
                  width={300}
                  height='auto'
                />
              </Col>
              <Col>
                <Row className='my-2'>
                  <Form.Group>
                    <Form.Label>Category</Form.Label>
                    <Form.Control
                      as='select'
                      value={rcategory}
                      onChange={(e) => setRcategory(e.target.value)}
                    >
                      <option value='New'>New</option>
                      <option value='Personal'>Personal</option>
                      <option value='Business'>Business</option>
                    </Form.Control>
                  </Form.Group>
                </Row>

                <Row className='my-2'>
                  <Form.Group>
                    <Form.Label>Meta Data</Form.Label>
                    <Form.Control
                      type='text'
                      as='textarea'
                      rows={3}
                      placeholder='Meta Data'
                      value={rmeta}
                      onChange={(e) => setRmeta(e.target.value)}
                    />
                  </Form.Group>
                </Row>

                <Row className='my-2'>
                  <Form.Group>
                    <Form.Label>Comments</Form.Label>
                    <Form.Control
                      as='textarea'
                      rows={3}
                      type='text'
                      placeholder='Comments'
                      value={rcptext}
                      onChange={(e) => setRcptext(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Row>
                <Row className='my-2'>
                  <Button variant='primary' type='submit' size='sm'>
                    Update
                  </Button>
                </Row>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  )
}

export default ReceiptUpdateScreen
