export const USER_RECEIPT_REQUEST = 'USER_RECEIPT_REQUEST'
export const USER_RECEIPT_SUCCESS = 'USER_RECEIPT_SUCCESS'
export const USER_RECEIPT_FAIL = 'USER_RECEIPT_FAIL'
export const USER_RECEIPT_RESET = 'USER_RECEIPT_RESET'

export const CATEGORY_RECEIPTS_REQUEST = 'CATEGORY_RECEIPTS_REQUEST'
export const CATEGORY_RECEIPTS_SUCCESS = 'CATEGORY_RECEIPTS_SUCCESS'
export const CATEGORY_RECEIPTS_FAIL = 'CATEGORY_RECEIPTS_FAIL'
export const CATEGORY_RECEIPTS_RESET = 'CATEGORY_RECEIPTS_RESET'

export const DELETE_RECEIPTS_REQUEST = 'DELETE_RECEIPTS_REQUEST'
export const DELETE_RECEIPTS_SUCCESS = 'DELETE_RECEIPTS_SUCCESS'
export const DELETE_RECEIPTS_FAIL = 'DELETE_RECEIPTS_FAIL'

export const UPDATE_RECEIPT_REQUEST = 'UPDATE_RECEIPT_REQUEST'
export const UPDATE_RECEIPT_SUCCESS = 'UPDATE_RECEIPT_SUCCESS'
export const UPDATE_RECEIPT_FAIL = 'UPDATE_RECEIPT_FAIL'
export const UPDATE_RECEIPT_RESET = 'UPDATE_RECEIPT_RESET'

export const RECEIPT_DETAIL_REQUEST = 'RECEIPT_DETAIL_REQUEST'
export const RECEIPT_DETAIL_SUCCESS = 'RECEIPT_DETAIL_SUCCESS'
export const RECEIPT_DETAIL_FAIL = 'RECEIPT_DETAIL_FAIL'
export const RECEIPT_DETAIL_RESET = 'RECEIPT_DETAIL_RESET'
