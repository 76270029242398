import {
  GET_NUMBER_OF_RECEIPTS_REQUEST,
  GET_NUMBER_OF_RECEIPTS_SUCCESS,
  GET_NUMBER_OF_RECEIPTS_FAIL,
  GET_RECEIPT_METAINFO_REQUEST,
  GET_RECEIPT_METAINFO_SUCCESS,
  GET_RECEIPT_METAINFO_FAIL
} from '../constants/dashboardConstants.js'

export const countRcptReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_NUMBER_OF_RECEIPTS_REQUEST:
      return { loading: true }

    case GET_NUMBER_OF_RECEIPTS_SUCCESS:
      return { loading: false, countRecpt: action.payload }

    case GET_NUMBER_OF_RECEIPTS_FAIL:
      return { loading: false, error: action.payload }

    default:
      return state
  }
}

export const getRcptMetaReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_RECEIPT_METAINFO_REQUEST:
      return { loading: true }

    case GET_RECEIPT_METAINFO_SUCCESS:
      return { loading: false, receiptMeta: action.payload }

    case GET_RECEIPT_METAINFO_FAIL:
      return { loading: false, error: action.payload }

    default:
      return state
  }
}
