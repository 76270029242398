/**
 * Reducer function for handling receipt spend actions.
 *
 * @param {Object} state - The current state.
 * @param {Object} action - The action object.
 * @param {string} action.type - The type of action.
 * @param {any} action.payload - The payload of the action.
 * @returns {Object} The updated state.
 */
import {
  RECEIPT_SPEND_REQUEST,
  RECEIPT_SPEND_SUCCESS,
  RECEIPT_SPEND_FAILURE
} from '../constants/metaConstants'

export const getReceiptSpendReducer = (state = {}, action) => {
  switch (action.type) {
    case RECEIPT_SPEND_REQUEST:
      return { loading: true }

    case RECEIPT_SPEND_SUCCESS:
      return { loading: false, receiptspend: action.payload }

    case RECEIPT_SPEND_FAILURE:
      return { loading: false, error: action.payload }

    default:
      return state
  }
}
