/**
 * Footer component for the application.
 *
 * @module Footer
 * @returns {JSX.Element} The rendered Footer component.
 */
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const Footer = () => {
  return (
    <footer>
      <Container>
        <Row>
          <Col className='text-center py-3'>
            Copyright &copy; Hermanos Gazpachos Inc. 2024
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
