import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Message from '../components/Message.js'
import Loader from '../components/Loader.js'
import { register, logout } from '../actions/userActions.js'
import FormContainer from '../components/FormContainer.js'
import { USER_REGISTER_RESET } from '../constants/userConstants.js'

const RegisterScreen = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [message, setMessage] = useState(null)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const inputRef = useRef()

  const userRegister = useSelector((state) => state.userRegister)
  const { loading, error, userInfo } = userRegister

  useEffect(() => {
    if (userInfo && !loading) {
      dispatch(logout())
      navigate('/login')
    } else {
      dispatch({ type: USER_REGISTER_RESET })
      inputRef.current.focus()
    }
  }, [navigate, userInfo, dispatch, loading])

  const submitHandler = (e) => {
    e.preventDefault()

    if (password !== confirmPassword) {
      setMessage('Passwords do not match')
    } else if (password.length < 6) {
      setMessage('Password must be at least 6 characters')
    } else {
      dispatch(register(name, email, password))
    }
  }

  return (
    <FormContainer>
      <h3>Sign Up</h3>
      {message && <Message variant='danger'>{message}</Message>}
      {error && <Message variant='danger'>{error}</Message>}
      {loading && <Loader />}
      <Form onSubmit={submitHandler}>
        <Form.Group className='mb-3' controlId='name'>
          <Form.Label>Name</Form.Label>
          <Form.Control
            ref={inputRef}
            type='name'
            autoFocus
            placeholder='Enter Name'
            value={name}
            autoComplete='name'
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>
        <Form.Group className='mb-3' controlId='email'>
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type='email'
            placeholder='Enter email'
            value={email}
            autoComplete='email'
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>
        <Form.Group className='mb-3' controlId='password'>
          <Form.Label>Password</Form.Label>
          <Form.Control
            type='password'
            placeholder='Enter Password (minimum 6 characters)'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        <Form.Group className='mb-3' controlId='confirmPassword'>
          <Form.Label>Password</Form.Label>
          <Form.Control
            type='password'
            placeholder='Confirm Password (minimum 6 characters)'
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </Form.Group>

        <Button type='submit' variant='info'>
          Sign Up
        </Button>
      </Form>

      <Row className='py-3'>
        <Col>
          Have an account? <Link to={'/login'}> Login Here</Link>
        </Col>
      </Row>
    </FormContainer>
  )
}

export default RegisterScreen
