/**
 * Paginate component for displaying pagination links.
 * @module Paginate
 * @param {Object} props - The props object.
 * @param {number} props.pages - The total number of pages.
 * @param {number} props.page - The current page number.
 * @returns {JSX.Element|null} The Paginate component.
 */
import React from 'react'
import { Pagination } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

const Paginate = ({ pages, page }) => {
  return (
    pages > 1 && (
      <Pagination>
        {[...Array(pages).keys()].map((x) => (
          <LinkContainer key={x + 1} to={`/page/${x + 1}`}>
            <Pagination.Item active={x + 1 === page}>{x + 1}</Pagination.Item>
          </LinkContainer>
        ))}
      </Pagination>
    )
  )
}

export default Paginate
