/**
 * @module Loader
 * @desc A React component that renders a loading spinner.
 */
import React from 'react'
import { Spinner } from 'react-bootstrap'

function Loader() {
  return (
    <Spinner
      animation='border text-primary'
      role='status'
      style={{
        width: '90px',
        height: '90px',
        margin: 'auto',
        display: 'block'
      }}
    >
      <span className='sr-only'>Loading...</span>
    </Spinner>
  )
}

export default Loader
