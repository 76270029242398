import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import Header from './components/Header.js'
import Footer from './components/Footer.js'
import HomeScreen from './screens/HomeScreen.js'
import LoginScreen from './screens/LoginScreen.js'
import About from './screens/About.js'
import ContactScreen from './screens/ContactScreen.js'
import RegisterScreen from './screens/RegisterScreen.js'
import DashBoard from './screens/DashBoard.js'
import ReceiptScreen from './screens/ReceiptScreen.js'
import ProfileScreen from './screens/ProfileScreen.js'
import SearchBox from './components/SearchBox.js'
import ReceiptUpdateScreen from './screens/ReceiptUpdateScreen.js'
import CatReceiptScreen from './screens/CatReceiptScreen.js'
import UserListScreen from './screens/UserListScreen.js'
import UserEditScreen from './screens/UserEditScreen.js'

const App = () => {
  return (
    <main className='py-3'>
      <Container>
        <Header />
        <Routes>
          <Route path='/' element={<HomeScreen />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<ContactScreen />} />
          <Route path='/login' element={<LoginScreen />} />
          <Route path='/register' element={<RegisterScreen />} />
          <Route path='/receipts' element={<ReceiptScreen />} />
          <Route path='/category' element={<CatReceiptScreen />} />
          <Route path='/receipts/:id' element={<ReceiptScreen />} />
          <Route path='/receipts/edit/:id' element={<ReceiptUpdateScreen />} />
          <Route path='/dashboard' element={<DashBoard />} />
          <Route path='/profile' element={<ProfileScreen />} />
          <Route path='/search/:keyword' element={<ReceiptScreen />} />
          <Route path='/page/:pageNumber' element={<ReceiptScreen />} />
          <Route
            path='/catpage/:catpageNumber'
            element={<CatReceiptScreen />}
          />
          <Route path='/search' element={<SearchBox />} />
          <Route path='*' element={<h1>404 Not Found</h1>} />

          <Route path='/userlist' element={<UserListScreen />} />
          <Route path='/modifyuser/:id' element={<UserEditScreen />} />
        </Routes>
        <Footer />
      </Container>
    </main>
  )
}

export default App
