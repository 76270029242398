import {
  USER_RECEIPT_REQUEST,
  USER_RECEIPT_SUCCESS,
  USER_RECEIPT_FAIL,
  USER_RECEIPT_RESET,
  DELETE_RECEIPTS_REQUEST,
  DELETE_RECEIPTS_SUCCESS,
  DELETE_RECEIPTS_FAIL,
  UPDATE_RECEIPT_REQUEST,
  UPDATE_RECEIPT_SUCCESS,
  UPDATE_RECEIPT_FAIL,
  UPDATE_RECEIPT_RESET,
  RECEIPT_DETAIL_REQUEST,
  RECEIPT_DETAIL_SUCCESS,
  RECEIPT_DETAIL_FAIL,
  CATEGORY_RECEIPTS_REQUEST,
  CATEGORY_RECEIPTS_SUCCESS,
  CATEGORY_RECEIPTS_FAIL,
  CATEGORY_RECEIPTS_RESET
} from '../constants/receiptConstants.js'

export const myReceiptsReducer = (state = { receipts: [] }, action) => {
  switch (action.type) {
    case USER_RECEIPT_REQUEST:
      return { ...state, loading: true }

    case USER_RECEIPT_SUCCESS:
      return {
        loading: false,
        error: action.paylod,
        receipts: action.payload.receipts,
        pages: action.payload.pages,
        page: action.payload.page,
        count: action.payload.count
      }

    case USER_RECEIPT_FAIL:
      return { loading: false, error: action.payload }

    case USER_RECEIPT_RESET:
      return {}

    default:
      return state
  }
}

export const categoryReceiptsReducer = (state = { receipts: [] }, action) => {
  switch (action.type) {
    case CATEGORY_RECEIPTS_REQUEST:
      return { ...state, loading: true }

    case CATEGORY_RECEIPTS_SUCCESS:
      return {
        catloading: false,
        catreceipts: action.payload.catreceipts,
        catpages: action.payload.catpages,
        catpage: action.payload.catpage,
        catcount: action.payload.catcount
      }

    case CATEGORY_RECEIPTS_FAIL:
      return { loading: false, error: action.payload }

    case CATEGORY_RECEIPTS_RESET:
      return {}

    default:
      return state
  }
}

export const deleteReceiptsReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_RECEIPTS_REQUEST:
      return { loading: true }

    case DELETE_RECEIPTS_SUCCESS:
      return { loading: false, success: true }

    case DELETE_RECEIPTS_FAIL:
      return { loading: false, error: action.payload }

    default:
      return state
  }
}

export const updateReceiptReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_RECEIPT_REQUEST:
      return { loading: true }

    case UPDATE_RECEIPT_SUCCESS:
      return { loading: false, success: true, receiptUpdate: action.payload }

    case UPDATE_RECEIPT_FAIL:
      return { loading: false, error: action.payload }

    case UPDATE_RECEIPT_RESET:
      return {}

    default:
      return state
  }
}

export const detailReceiptReducer = (state = { receipt: {} }, action) => {
  switch (action.type) {
    case RECEIPT_DETAIL_REQUEST:
      return { ...state, loading: true }

    case RECEIPT_DETAIL_SUCCESS:
      return { loading: false, receipt: action.payload }

    case RECEIPT_DETAIL_FAIL:
      return { loading: false, error: action.payload }

    default:
      return state
  }
}
