import {
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  ADMIN_GET_USER_REQUEST,
  ADMIN_GET_USER_SUCCESS,
  ADMIN_GET_USER_FAIL,
  ADMIN_UPDATE_USER_REQUEST,
  ADMIN_UPDATE_USER_SUCCESS,
  ADMIN_UPDATE_USER_FAIL,
  ADMIN_UPDATE_USER_RESET,
  ADMIN_DELETE_USER_REQUEST,
  ADMIN_DELETE_USER_SUCCESS,
  ADMIN_DELETE_USER_FAIL,
  ADMIN_DELETE_USER_RESET
} from '../constants/adminConstants'

export const userListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { ...state, loading: true }
    case USER_LIST_SUCCESS:
      return { loading: false, users: action.payload }
    case USER_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const adminGetUserReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case ADMIN_GET_USER_REQUEST:
      return { loading: true }
    case ADMIN_GET_USER_SUCCESS:
      return { loading: false, user: action.payload }
    case ADMIN_GET_USER_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const adminUpdateUserReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_UPDATE_USER_REQUEST:
      return { loading: true }
    case ADMIN_UPDATE_USER_SUCCESS:
      return { loading: false, success: true, user: action.payload }
    case ADMIN_UPDATE_USER_FAIL:
      return { loading: false, error: action.payload }
    case ADMIN_UPDATE_USER_RESET:
      return {}
    default:
      return state
  }
}

export const adminDeleteUserReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_DELETE_USER_REQUEST:
      return { loading: true }
    case ADMIN_DELETE_USER_SUCCESS:
      return { loading: false, success: true }
    case ADMIN_DELETE_USER_FAIL:
      return { loading: false, error: action.payload }
    case ADMIN_DELETE_USER_RESET:
      return {}
    default:
      return state
  }
}
