import {
    USER_CONTACT_REQUEST,
    USER_CONTACT_SUCCESS,
    USER_CONTACT_FAIL,
    USER_CONTACT_RESET
  } from '../constants/contactConstants.js';
  
  export const userContactReducer = (state = {}, action) => {
    switch (action.type) {
      case USER_CONTACT_REQUEST:
        return { loading: true };
  
      case USER_CONTACT_SUCCESS:
        return { loading: false, success: true, contactInfo: action.payload };
  
      case USER_CONTACT_FAIL:
        return { loading: false, error: action.payload };
  
      case USER_CONTACT_RESET:
        return {};
  
      default:
        return state;
    }
  };
  