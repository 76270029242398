import { applyMiddleware } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import {
  userLoginReducer,
  userRegisterReducer,
  //   userListReducer,
  //   userDeleteReducer,
  userDetailsReducer,
  userUpdateProfileReducer
  //   ownerRegisterReducer
} from './reducers/userReducers.js'

import {
  myReceiptsReducer,
  deleteReceiptsReducer,
  updateReceiptReducer,
  detailReceiptReducer,
  categoryReceiptsReducer
} from './reducers/receiptReducers.js'

import {
  countRcptReducer,
  getRcptMetaReducer
} from './reducers/dashboardReducers'

import { userContactReducer } from './reducers/contactReducers.js'

import { getReceiptSpendReducer } from './reducers/metaReducers.js'

import {
  userListReducer,
  adminGetUserReducer,
  adminUpdateUserReducer,
  adminDeleteUserReducer
} from './reducers/adminReducers.js'

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null

const initialState = {
  userLogin: { userInfo: userInfoFromStorage }
}

const middleware = [thunk]

const store = configureStore(
  {
    reducer: {
      userLogin: userLoginReducer,
      countRcpt: countRcptReducer,
      getRcptMeta: getRcptMetaReducer,
      userContact: userContactReducer,
      userRegister: userRegisterReducer,
      userList: userListReducer,
      //   userDelete: userDeleteReducer,
      userDetails: userDetailsReducer,
      // userUpdate: userUpdateReducer
      //   userProfile: userProfileReducer,
      userUpdateProfile: userUpdateProfileReducer,

      // adminRegister: adminRegisterReducer,
      adminUpdateUser: adminUpdateUserReducer,
      adminGetUser: adminGetUserReducer,
      adminDeleteUser: adminDeleteUserReducer,
      //   ownerRegister: ownerRegisterReducer
      myReceipts: myReceiptsReducer,
      deleteReceipts: deleteReceiptsReducer,
      updateReceipt: updateReceiptReducer,
      detailReceipt: detailReceiptReducer,
      categoryReceipts: categoryReceiptsReducer,
      getReceiptSpend: getReceiptSpendReducer
    },
    devTools: process.env.NODE_ENV !== 'production'
  },
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
