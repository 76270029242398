import axios from 'axios'

import {
  GET_NUMBER_OF_RECEIPTS_REQUEST,
  GET_NUMBER_OF_RECEIPTS_SUCCESS,
  GET_NUMBER_OF_RECEIPTS_FAIL,
  GET_RECEIPT_METAINFO_REQUEST,
  GET_RECEIPT_METAINFO_SUCCESS,
  GET_RECEIPT_METAINFO_FAIL
} from '../constants/dashboardConstants.js'

// Get number of receipts for user dashboard
export const getnumofRcpts = () => async (dispatch) => {
  try {
    dispatch({ type: GET_NUMBER_OF_RECEIPTS_REQUEST })

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    const { data } = await axios.get('/api/receipts/count', config)

    dispatch({ type: GET_NUMBER_OF_RECEIPTS_SUCCESS, payload: data })
  } catch (err) {
    dispatch({
      type: GET_NUMBER_OF_RECEIPTS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message
    })
  }
}

// Get metadata for user dashboard
export const getReceiptMetainfo = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_RECEIPT_METAINFO_REQUEST })

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    const { data } = await axios.get(`/api/receipts/receiptmeta/${id}`, config)

    dispatch({ type: GET_RECEIPT_METAINFO_SUCCESS, payload: data })
  } catch (err) {
    dispatch({
      type: GET_RECEIPT_METAINFO_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message
    })
  }
}
