import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button } from 'react-bootstrap'
import { sendcontact } from '../actions/contactActions'
import { USER_CONTACT_RESET } from '../constants/contactConstants.js'
import Message from '../components/Message.js'
import Loader from '../components/Loader.js'
import FormContainer from '../components/FormContainer.js'
import { getUserDetails } from '../actions/userActions'

const ContactScreen = () => {
  const [cname, setCname] = useState('')
  const [cemail, setCemail] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const inputRef = useRef()

  // const userDetails = useSelector((state) => state.userDetails)
  // const { user } = userDetails
  // console.log('User details: ' + JSON.stringify(user))

  const userContact = useSelector((state) => state.userContact)
  const { loading, success, error, contactInfo } = userContact

  useEffect(() => {
    if (contactInfo && success) {
      setCname('')
      setCemail('')
      setSubject('')
      setMessage('')
      navigate('/contact')
    } else {
      if (!cname || !cemail) {
        getUserDetails()
        dispatch({ type: USER_CONTACT_RESET })
        inputRef.current.focus()
      }
    }
  }, [navigate, dispatch, contactInfo, cname, cemail, success])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(sendcontact(cname, cemail, subject, message))
  }

  return (
    <FormContainer>
      <h1>Contact Us</h1>
      {error && <Message variant='danger'>{error}</Message>}{' '}
      {success && (
        <Message variant='success'>Message Successfully Sent</Message>
      )}
      {loading && <Loader />}
      <Form onSubmit={submitHandler}>
        <Form.Group className='mb-3' controlId='cname'>
          <Form.Label>Name</Form.Label>
          <Form.Control
            ref={inputRef}
            autoFocus
            type='cname'
            placeholder='Enter Name'
            value={cname}
            onChange={(e) => setCname(e.target.value)}
          />
        </Form.Group>
        <Form.Group className='mb-3' controlId='cemail'>
          <Form.Label>Email</Form.Label>
          <Form.Control
            type='cemail'
            placeholder='Email'
            value={cemail}
            onChange={(e) => setCemail(e.target.value)}
          />
        </Form.Group>
        <Form.Group className='mb-3' controlId='subject'>
          <Form.Label>Subject</Form.Label>
          <Form.Control
            type='subject'
            placeholder='Subject'
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </Form.Group>
        <Form.Group className='mb-3' controlId='message'>
          <Form.Label>Message</Form.Label>
          <Form.Control
            type='message'
            placeholder='Message'
            value={message}
            as='textarea'
            rows={6}
            onChange={(e) => setMessage(e.target.value)}
          />
        </Form.Group>

        <Button type='submit' variant='info'>
          Send Message
        </Button>
      </Form>
    </FormContainer>
  )
}

export default ContactScreen
