import React from 'react'
import { Container, Row, Card, Col } from 'react-bootstrap'
// import testclip from './qrceipt-video2.mp4'

const HomeScreen = () => {
  return (
    <div>
      <Container fluid>
        <Row>
          <Col sm={12} md={6} lg={4} xl={3}>
            <Card
              bg='white'
              className='my-3 p-3 rounded'
              border='primary'
              style={{ width: '15rem' }}
            >
              <Card.Header>
                <h4>Our mission</h4>
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  The elimination of all paper receipts via digitization.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className='my-3 p-3 rounded' border='primary' md='auto'>
              <Card.Header>
                <h4>For a greener tomorrow, today</h4>
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  There are millions of tonnes of paper per year that end up in
                  the waste/recycling stream, our assumption at a minimum is
                  that one million tonnes are sales receipts. Our technology
                  provides a receipt to you at checkout by scanning your unique
                  QR code. It is easy to use, contactless and evironmental.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6} lg={4} xl={3}>
            {/* <Card
              className='p-3 rounded'
              border='primary'
              style={{ width: '20rem' }}
            >
              <iframe
                title='embedsPage'
                className='embed-responsive-item'
                src={testclip}
                allowFullScreen
                sandbox=''
              />

              <Card.Body>
                <h4>Geranium Receipt in action.</h4>
              </Card.Body>
            </Card> */}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default HomeScreen
