import axios from 'axios'
import {
  RECEIPT_SPEND_REQUEST,
  RECEIPT_SPEND_SUCCESS,
  RECEIPT_SPEND_FAILURE
} from '../constants/metaConstants'

export const getReceiptSpendTotal = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RECEIPT_SPEND_REQUEST
    })

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    const { data } = await axios.get(`/api/meta/allmeta/${id}`, config)

    dispatch({
      type: RECEIPT_SPEND_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: RECEIPT_SPEND_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    })
  }
}
