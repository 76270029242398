import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

const About = () => {
  return (
    <Fragment>
      <div className='about'>
        <div className='div1'>
          <h2>
            <strong>About Us</strong>
          </h2>
          <p>
            Our company produces electronic receipt software for point of sale
            systems.
          </p>
          <p>
            The idea came about from seeing how many receipts after a purchase
            end up in the trash, directly to the waste stream.
          </p>
          <p>
            With a scan of your personal QR code you will receive an electronic
            receipt at the time of purchase. By creating an account you can
            search, print receipts and more.
          </p>
          <p>
            The QR code triggers software which connects with your individual
            account and transmits the electronic version of the receipt to you.
          </p>
          <p>
            You can become a member by creating your account with us. When you
            create your account you will get assigned a unique QR code to use at
            checkout.
          </p>

          <h4>Business can use technology that enables:</h4>

          <ul style={{ listStyleType: 'none' }}>
            <li>
              <i className='far fa-check-square' /> Contacless receipt
            </li>
            <li>
              <i className='far fa-check-square' /> Elimination of the need for
              paper and ink
            </li>
            <li>
              <i className='far fa-check-square' /> Reduced fraud
            </li>
            <li>
              <i className='far fa-check-square' /> A faster checkout experience
            </li>
          </ul>

          <p>
            For futher information <Link to='/contact'>contact us</Link>
          </p>
        </div>
      </div>
    </Fragment>
  )
}

export default About
