import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Button } from 'react-bootstrap'
import { useNavigate, useParams, Link, useLocation } from 'react-router-dom'
import Message from '../components/Message'
import Loader from '../components/Loader'

import {
  deleteReceipt,
  getReceipt,
  getcategoryReceipts
} from '../actions/receiptActions.js'
import CatPaginate from '../components/CatPaginate.js'
import moment from 'moment'

const CatReceiptScreen = () => {
  const { catpageNumber } = useParams() || 1
  const [currentcat, setCurrentcat] = useState('')

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const categoryReceipts = useSelector((state) => state.categoryReceipts)
  const { catreceipts, loading, catpages, catpage, catcount } = categoryReceipts

  const deleteReceipts = useSelector((state) => state.deleteReceipts)
  const { success } = deleteReceipts

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const deleteHandler = (rimgid) => {
    if (window.confirm('Are you sure')) {
      dispatch(deleteReceipt(rimgid))
    }
  }

  const editHandler = (id) => {
    navigate(`/receipts/edit/${id}`)
    dispatch(getReceipt(id))
  }

  const location = useLocation()
  const { category } = location.state || ''

  useEffect(() => {
    if ((userInfo || success) && location.state !== null) {
      setCurrentcat(category)
      dispatch(getcategoryReceipts(catpageNumber, category))
    } else if (location.state === null || location.state === undefined) {
      dispatch(getcategoryReceipts(catpageNumber, currentcat))
    } else {
      navigate('/dashboard')
    }
  }, [
    location,
    category,
    userInfo,
    catcount,
    catpageNumber,
    currentcat,
    success,
    dispatch,
    navigate
  ])

  return (
    <Container className='my-2' fluid>
      {loading && <Loader />}
      {catcount === 0 && <Message variant='info'>None Found</Message>}
      <CatPaginate catpages={catpages} catpage={catpage} />
      <Row>
        {catreceipts &&
          catreceipts.length > 0 &&
          catreceipts.map((receipt) => (
            <Col key={receipt._id} sm={12} md={6} lg={4} xl={3}>
              <Card className='my-3 p-3 rounded' style={{ width: '18rem' }}>
                <Card.Header className='bg-secondary'>
                  <Button className='btn btn-sm'>
                    {receipt.rcategory} <i className='fas fa-tag'></i>
                  </Button>
                </Card.Header>

                <Card.Body>
                  <Card.Img
                    src={`/static/${receipt.qfname}`}
                    variant='top'
                    alt=''
                  />
                  <Link to={`/receipts/${receipt.rimgid}`}>
                    <Card.Title as='div'>
                      {moment(receipt.createdAt).format(
                        'ddd DD MMM YYYY h:mm A'
                      )}
                    </Card.Title>
                  </Link>
                  <Card.Text as='div'>{receipt.rcptext}</Card.Text>
                </Card.Body>

                <Card.Footer>
                  <Button
                    variant='primary'
                    block='true'
                    onClick={() => editHandler(receipt._id)}
                  >
                    <i className='fas fa-edit'></i>
                  </Button>{' '}
                  <Button
                    variant='danger'
                    block='true'
                    onClick={() => deleteHandler(receipt.rimgid)}
                  >
                    <i className='fas fa-trash'></i>
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
          ))}
      </Row>
    </Container>
  )
}

export default CatReceiptScreen
