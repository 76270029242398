import React, { useState, useEffect } from 'react'
import { Container, Button, Modal, Row, Col, Card } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getnumofRcpts, getReceiptMetainfo } from '../actions/dashboardActions'
import { getMyReceipts } from '../actions/receiptActions.js'
import { getReceiptSpendTotal } from '../actions/metaActions.js'

const DashBoard = () => {
  let targetdir = ''

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const id = userInfo._id

  if (userInfo) {
    const { qrfile } = userInfo
    targetdir = `/qrcode/${qrfile}`
  }

  const countRcpt = useSelector((state) => state.countRcpt)
  const { countRecpt } = countRcpt

  const getRcptMeta = useSelector((state) => state.getRcptMeta)
  const { receiptMeta } = getRcptMeta

  const getReceiptSpend = useSelector((state) => state.getReceiptSpend)
  const { receiptspend } = getReceiptSpend

  useEffect(() => {
    if (!userInfo) {
      navigate('/')
    } else {
      dispatch(getnumofRcpts())
      dispatch(getMyReceipts())
      dispatch(getReceiptMetainfo(id))
      dispatch(getReceiptSpendTotal(id))
    }
  }, [dispatch, navigate, id, userInfo])

  return (
    <Container fluid>
      <Row>
        <Col className='my-2'>
          <h4>DashBoard</h4>
        </Col>
        <Col sm={4} className='my-2'>
          <Button variant='primary' block='true' onClick={handleShow}>
            My QR
          </Button>
        </Col>
      </Row>

      <Row>
        <Modal size='sm' show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Scan QR</Modal.Title>
          </Modal.Header>
          <Modal.Body className='text-center'>
            <img src={targetdir} alt=' ' width='auto' height='auto' />
          </Modal.Body>
          <Modal.Footer>
            <Button variant='primary' size='sm' onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Row>

      <Row>
        <Col>
          <Card
            bg={'light'}
            border={'primary'}
            width={{ width: '18rem' }}
            className='mb-2'
          >
            <Card.Title className='align-center my-1 mx-1'>Receipts</Card.Title>
            <Card.Body>You have {countRecpt} receipts.</Card.Body>
          </Card>
        </Col>
        <Col>
          <Card
            bg={'light'}
            border={'primary'}
            width={{ width: '18rem' }}
            className='mb-2'
          >
            <Card.Title className='mx-1 my-1'>Spend</Card.Title>
            <Card.Body>To date you have spent {receiptspend}</Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card
            bg={'light'}
            border={'primary'}
            width={{ width: '18rem' }}
            className='mb-2'
            // style={{ height: '30%' }}
          >
            <Card.Title className='my-2 mx-2'>Receipt Categories</Card.Title>
            <Card.Body>
              <ul>
                {receiptMeta &&
                  receiptMeta.map((item, index) => (
                    <Card.Text key={index}>
                      <li>
                        {item.meta}: {item.count}
                      </li>
                    </Card.Text>
                  ))}
              </ul>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default DashBoard
