/**
 * Represents a Message component.
 * @module Message
 * @param {Object} props - The component props.
 * @param {string} props.variant - The variant of the message. Defaults to 'info'.
 * @param {ReactNode} props.children - The content of the message.
 * @returns {JSX.Element} The rendered Message component.
 */
import React, { useState } from 'react'
import { Alert, Button } from 'react-bootstrap'

function Message({ variant, children }) {
  const [show, setShow] = useState(true)

  return (
    <Alert show={show} variant={variant}>
      {children}
      <div className='d-flex justify-content-end'>
        <Button onClick={() => setShow(false)} variant={variant}>
          Close
        </Button>
      </div>
    </Alert>
  )
}

Message.defaultProps = {
  variant: 'info'
}

export default Message
