/**
 * ReceiptScreen component.
 *
 * This component is responsible for rendering the receipt screen, which displays a list of receipts.
 * It fetches the receipts from the server and allows the user to delete and edit receipts.
 *
 * @returns {JSX.Element} The rendered ReceiptScreen component.
 */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Button, Alert, Form } from 'react-bootstrap'
import { useNavigate, useParams, Link } from 'react-router-dom'
// import Message from '../components/Message'
import Loader from '../components/Loader'

import {
  getMyReceipts,
  deleteReceipt,
  getReceipt
} from '../actions/receiptActions.js'
import Paginate from '../components/Paginate.js'

import moment from 'moment'
import { CATEGORY_RECEIPTS_RESET } from '../constants/receiptConstants'

const ReceiptScreen = () => {
  const [selected, setSelected] = useState(false)
  const [alert, setAlert] = useState({
    show: false,
    message: '',
    variant: 'success'
  })
  const { keyword } = useParams() || ''
  const { pageNumber } = useParams() || 1

  if (selected) {
    console.log('Selected:', selected)
  }

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const myReceipts = useSelector((state) => state.myReceipts)
  const { receipts, loading, pages, page, count } = myReceipts

  const deleteReceipts = useSelector((state) => state.deleteReceipts)
  const { success } = deleteReceipts

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const countRcpt = useSelector((state) => state.countRcpt)
  const { countRecpt } = countRcpt

  // Change handler
  function showAlert(message, variant = 'success', seconds) {
    setAlert({
      show: true,
      message,
      variant
    })

    setTimeout(() => {
      setAlert({
        show: false,
        message: '',
        variant: 'success'
      })
    }, seconds)
  }

  const deleteHandler = (rimgid) => {
    if (window.confirm('Are you sure')) {
      dispatch(deleteReceipt(rimgid))
      showAlert('Receipt Deleted', 'success', 1000)
    }
  }

  const editHandler = (id) => {
    dispatch(getReceipt(id))
    navigate(`/receipts/edit/${id}`)
  }

  useEffect(() => {
    if ((userInfo || success) && countRecpt > 0) {
      dispatch(getMyReceipts(pageNumber, keyword))
      dispatch({ type: CATEGORY_RECEIPTS_RESET })
    } else {
      navigate('/dashboard')
    }
  }, [dispatch, navigate, userInfo, success, keyword, countRecpt, pageNumber])

  useEffect(() => {
    if (count === 0) {
      showAlert('No Receipts Found', 'info', 1000)
      navigate('/receipts')
    }
  }, [navigate, count, alert])

  return (
    <Container className='my-2' fluid>
      {alert.show && <Alert variant={alert.variant}>{alert.message}</Alert>}
      {loading && <Loader />}
      {receipts && !loading && <Paginate pages={pages} page={page} />}

      <Row>
        {!loading &&
          receipts &&
          receipts.map((receipt) => (
            <Col key={receipt._id} sm={12} md={6} lg={4} xl={3}>
              <Card className='my-3 p-3 rounded' style={{ width: '18rem' }}>
                <Card.Header className='bg-secondary'>
                  <Button
                    className='btn btn-sm'
                    onClick={() =>
                      navigate(`/category`, {
                        state: { category: receipt.rcategory }
                      })
                    }
                  >
                    {receipt.rcategory} <i className='fas fa-tag'></i>
                  </Button>
                </Card.Header>

                <Card.Body>
                  <Card.Img
                    src={`/static/${receipt.qfname}`}
                    variant='top'
                    alt=''
                  />
                  <Link to={`/receipts/${receipt.rimgid}`}>
                    <br />
                    <Card.Title as='div'>
                      {moment(receipt.createdAt).format(
                        'ddd DD MMM YYYY h:mm A'
                      )}
                    </Card.Title>
                  </Link>
                  <Card.Text as='div'>{receipt.rcptext}</Card.Text>
                </Card.Body>

                <Card.Footer>
                  <Button
                    variant='primary'
                    block='true'
                    onClick={() => editHandler(receipt._id)}
                  >
                    <i className='fas fa-edit'></i>
                  </Button>{' '}
                  <Button
                    variant='danger'
                    block='true'
                    onClick={() => deleteHandler(receipt.rimgid)}
                  >
                    <i className='fas fa-trash'></i>
                  </Button>
                  <br />
                  <Form.Check
                    type='checkbox'
                    label='Select'
                    onClick={(e) => setSelected(e.target.checked)}
                  />
                </Card.Footer>
              </Card>
            </Col>
          ))}
      </Row>
    </Container>
  )
}

export default ReceiptScreen
