/**
 * CatPaginate component.
 *
 * @module CatPaginate
 * @param {Object} props - The component props.
 * @param {number} props.catpages - The total number of catpages.
 * @param {number} props.catpage - The current catpage.
 * @returns {JSX.Element|null} The CatPaginate component.
 */
import React from 'react'
import { Pagination } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

const CatPaginate = ({ catpages, catpage }) => {
  return (
    catpages > 1 && (
      <Pagination>
        {[...Array(catpages).keys()].map((z) => (
          <LinkContainer key={z + 1} to={`/catpage/${z + 1}`}>
            <Pagination.Item active={z + 1 === catpage}>
              {z + 1}
            </Pagination.Item>
          </LinkContainer>
        ))}
      </Pagination>
    )
  )
}

export default CatPaginate
