import axios from 'axios'

// Constants
import {
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  ADMIN_GET_USER_REQUEST,
  ADMIN_GET_USER_SUCCESS,
  ADMIN_GET_USER_FAIL,
  ADMIN_UPDATE_USER_REQUEST,
  ADMIN_UPDATE_USER_SUCCESS,
  ADMIN_UPDATE_USER_FAIL
} from '../constants/adminConstants'

// Get all users
export const listUsers = () => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_LIST_REQUEST })

    // Get user info
    const {
      userLogin: { userInfo }
    } = getState()

    // Set header
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    // Get data
    if (userInfo.isAdmin) {
      const { data } = await axios.get(`/api/admin/getallusers`, config)
      dispatch({ type: USER_LIST_SUCCESS, payload: data })
    }
  } catch (error) {
    dispatch({
      type: USER_LIST_FAIL,
      payload:
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.msg
    })
  }
}

// Get user by id
export const getUserDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_GET_USER_REQUEST
    })

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    const { data } = await axios.get(`/api/admin/user/${id}`, config)

    dispatch({
      type: ADMIN_GET_USER_SUCCESS,
      payload: data
    })
  } catch (e) {
    dispatch({
      type: ADMIN_GET_USER_FAIL,
      payload:
        e.response && e.response.data.message
          ? e.response.data.message
          : e.message
    })
  }
}

// Update user
export const updateUser = (user) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_UPDATE_USER_REQUEST
    })

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    const { data } = await axios.put(`/api/admin/modifyuser`, user, config)

    dispatch({
      type: ADMIN_UPDATE_USER_SUCCESS,
      payload: data
    })
  } catch (e) {
    dispatch({
      type: ADMIN_UPDATE_USER_FAIL,
      payload:
        e.response && e.response.data.message
          ? e.response.data.message
          : e.message
    })
  }
}

// Delete user
export const deleteUser = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_UPDATE_USER_REQUEST
    })

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    const { data } = await axios.delete(`/api/admin/deleteuser/${id}`, config)

    dispatch({
      type: ADMIN_UPDATE_USER_SUCCESS,
      payload: data
    })
  } catch (e) {
    dispatch({
      type: ADMIN_UPDATE_USER_FAIL,
      payload:
        e.response && e.response.data.message
          ? e.response.data.message
          : e.message
    })
  }
}
