import axios from 'axios'
import {
  USER_RECEIPT_REQUEST,
  USER_RECEIPT_SUCCESS,
  USER_RECEIPT_FAIL,
  DELETE_RECEIPTS_REQUEST,
  DELETE_RECEIPTS_SUCCESS,
  DELETE_RECEIPTS_FAIL,
  UPDATE_RECEIPT_REQUEST,
  UPDATE_RECEIPT_SUCCESS,
  UPDATE_RECEIPT_FAIL,
  RECEIPT_DETAIL_REQUEST,
  RECEIPT_DETAIL_SUCCESS,
  RECEIPT_DETAIL_FAIL,
  CATEGORY_RECEIPTS_REQUEST,
  CATEGORY_RECEIPTS_SUCCESS,
  CATEGORY_RECEIPTS_FAIL
} from '../constants/receiptConstants'

export const getMyReceipts =
  (pageNumber = '', keyword = '') =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: USER_RECEIPT_REQUEST })

      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      const { data } = await axios.get(
        `/api/receipts/myreceipt?pageNumber=${pageNumber}&keyword=${keyword}`,
        config
      )

      dispatch({ type: USER_RECEIPT_SUCCESS, payload: data })
    } catch (err) {
      dispatch({
        type: USER_RECEIPT_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message
      })
    }
  }

export const getcategoryReceipts =
  (catpageNumber = '', category) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: CATEGORY_RECEIPTS_REQUEST })

      const {
        userLogin: { userInfo }
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`
        }
      }

      const { data } = await axios.get(
        `/api/receipts/category?catpageNumber=${catpageNumber}&category=${category}`,
        config
      )

      dispatch({ type: CATEGORY_RECEIPTS_SUCCESS, payload: data })
    } catch (err) {
      dispatch({
        type: CATEGORY_RECEIPTS_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message
      })
    }
  }

export const deleteReceipt = (rimgid) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_RECEIPTS_REQUEST })

    const config = {
      header: {
        'Content-Type': 'application/json'
      }
    }

    await axios.delete(
      '/api/receipts/delreceipts',
      { data: { rimgid: rimgid } },
      config
    )

    dispatch({ type: DELETE_RECEIPTS_SUCCESS })
  } catch (err) {
    dispatch({
      type: DELETE_RECEIPTS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message
    })
  }
}

export const receiptUpdate = (receipt) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_RECEIPT_REQUEST })

    const {
      userLogin: { userInfo }
    } = getState()

    const config = {
      header: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    await axios.put(`/api/receipts/edit/${receipt._id}`, receipt, config)

    dispatch({ type: UPDATE_RECEIPT_SUCCESS })
  } catch (err) {
    dispatch({
      type: UPDATE_RECEIPT_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message
    })
  }
}

export const getReceipt = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: RECEIPT_DETAIL_REQUEST })

    const {
      userLogin: { userInfo }
    } = getState()

    const config = {
      header: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.get(`/api/receipts/edit/${id}`, config)

    dispatch({ type: RECEIPT_DETAIL_SUCCESS, payload: data })
  } catch (err) {
    dispatch({
      type: RECEIPT_DETAIL_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message
    })
  }
}
